import axiosInstance from 'auth/FetchInterceptor'
import { PUBLIC_REQUEST_KEY } from 'constants/ApiConstant'
import { computeHmac } from 'utils/hmac'

export const adminFetchLoginConfig = (email) => {
  return axiosInstance({
    url: `auth/fetch-login-configurations`,
    headers: {
      [PUBLIC_REQUEST_KEY]: 'true',
      ...computeHmac(),
    },
    method: 'post',
    data: { email },
  })
}

export const idpAuth = (code) => {
  return axiosInstance({
    url: `auth/idp-login`,
    headers: {
      [PUBLIC_REQUEST_KEY]: 'true',
      ...computeHmac(),
    },
    method: 'post',
    data: { code },
  })
}

export const isLoggedIn = () => {
  return axiosInstance({
    url: `auth/logged-in`,
    method: 'post',
    headers: {
      ...computeHmac(),
    },

  }).catch((error) => {
    console.log(error);
  })
}

