export const WEBFILTER_POLICIES_API = 'web-filter-policies'
export const BLOCKED_URLS_API = 'blocked-url-policy?join=urls'
export const ALLOWED_URLS_API = 'allowed-url-policy?join=urls'
export const MIXED_URLS_API = 'mixed-url-policy?join=urls'
export const ACCESSIBLE_URLS_API = 'accessible-urls'
export const GET_MFA_AUTH_RULE_API =
  'authentication-policies?fields=id&join=createdBy||id&join=updatedBy||id&join=multiFactorAuthRule'
export const GET_ACTIVE_SESSION_API =
  'authentication-policies?fields=id&join=createdBy||id&join=updatedBy||id&join=activeSessionRule'
export const TMFA_POLICY_RULE_API =
  'authentication-policies?join=transactionalMfaRule&join=updatedBy||id&join=createdBy||id&fields=id'
export const GET_AUTH_RULE_API =
  'authentication-policies?join=activeSessionRule&join=multiFactorAuthRule&join=transactionalMfaRule&join=updatedBy||id&join=createdBy||id&fields=id,keyLogger&join=personalLoginRule||isEnabled&join=hibernateLogOffRule||isEnabled&join=rpaRule||isEnabled&join=forceLoginRule||isEnabled&join=loginDomainsEnforcementRule'
export const MFA_AUTH_RULE_API = 'authentication-policy/multi-factor-auth-rule'
export const TRANSACTIONAL_MFA_API = 'authentication-policy/transactional-mfa-rule'
export const TRANSACTIONAL_MFA_API_RULE =
  'authentication-policies?join=transactionalMfaRule&join=updatedBy||id&join=createdBy||id&fields=id'
export const ACTIVE_SESSION_API = 'authentication-policy/active-session-rule'
export const TRANSACTIONAL_MFA_URL_API = 'authentication-policy/transactional-mfa-url'
export const GET_EXTENSION_POLICY_RULE_API = 'extension-policies?join=chromeStoreRule'
export const PATCH_EXTENSION_POLICIY_API = 'extension-policy/chrome-store-rule'
export const ALLOW_EXTENSION_API = 'extensions-policy/allowed-browser-extensions'
export const PHISHING_POLICY_WITH_RULES_API =
  'phishing-policies?join=trustedDomainRule&join=exceptionDomainRule&join=commonSaasRule&join=phishingRegexRule'
export const AUTO_HIBERNATE_LOGOUT_API = 'authentication-policy/hibernate-logoff-rule'
export const RPA_RULE_API = 'authentication-policy/rpa-rule'
export const GATEWAY_CONTROL = 'gateway-control'
export const PHISHING_POLICY_PROTECTION_METHOD = 'phishing-policies?join=phishingRegexRule'
export const PHISHING_TRUSTED_LINKS_API =
  'phishing-policies?join=trustedDomainRule&join=trustedDomainRule.links'
export const PHISHING_SAAS_LINKS_API =
  'phishing-policies?join=commonSaasRule&join=commonSaasRule.saasRuleLinks&join=commonSaasRule.saasRuleLinks.saasLink'
export const PHISHING_EXCEPTIONS_LINKS_API =
  'phishing-policies?join=exceptionDomainRule&join=exceptionDomainRule.links'
export const PHISHING_URLS_API = 'phishing-policy/links'
export const SAAS_RULE_LINKS_API = 'saas-rule-links'
export const WEBFILTER_REGEX_FILTER =
  'web-filter-policies?fields=isEnabled,iframeMonitorEnabled&join=regexFiltering'
export const WEBFILTER_CATEGORIES_API =
  'web-filter-policies?join=urlClassificationRules&join=urlClassificationRules.urlClassification'
export const URL_CLASSIFICATION_API = 'web-filter-policy/url-classification-rule'
export const GET_APPROVED_FILE_EXTENSION_API =
  'download-policies?join=approvedFileExtensionsRule&join=approvedFileExtensionsRule.fileExtensionGroupRules&join=approvedFileExtensionsRule.fileExtensionGroupRules.fileExtensionsGroup&join=approvedFileExtensionsRule.fileExtensionGroupRules.fileExtensionsGroup.fileExtensions&join=downloadPreventionAdvancedPolicies||id,isEnabled,blockAll,blockedMimeTypes'
export const GET_ALERT_FILE_EXTENSION_API =
  'download-policies?join=alertFileExtensionsRule&join=alertFileExtensionsRule.fileExtensionGroupRules&join=alertFileExtensionsRule.fileExtensionGroupRules.fileExtensionsGroup&join=alertFileExtensionsRule.fileExtensionGroupRules.fileExtensionsGroup.fileExtensions'
export const GET_FILE_ENCRYPTIONS_API =
  'download-policies?join=fileEncryptionRule&join=fileEncryptionRule.fileExtensionGroupRules&join=fileEncryptionRule.fileExtensionGroupRules.fileExtensionsGroup&join=fileEncryptionRule.fileExtensionGroupRules.fileExtensionsGroup.fileExtensions'
export const GET_SCRAPING_API =
  'download-policies?join=scrappingRule&join=scrappingRule.scrappingConfigurations&fields=id&join=createdBy||id&join=updatedBy||id'
export const GET_DOWNLOAD_PROTECTION_API =
  'download-policies?fields=id&join=createdBy||id&join=updatedBy||id&join=downloadProtection||configurations'
export const IS_ENABLE_LOCAL_DOWNLOAD_API =
  'download-policies?join=approvedFileExtensionsRule||isEnabled&join=alertFileExtensionsRule||isEnabled&fields=id,allowAccessRequest&join=createdBy||id&join=updatedBy||id&join=scrappingRule&join=secureStorageRule&join=fileEncryptionRule&join=downloadProtection||isEnabled&join=scrappingRule.scrappingConfigurations&join=downloadPreventionAdvancedPolicies'
export const FILE_EXTENSION_GROUP_RULE_API = 'download-policy/file-extension-group-rule'
export const SCRAPING_CONFIG_API = 'download-policy/scrapping-configuration'
export const URL_CLASSIFICATION_TOGGLE_ALL_API =
  'web-filter-policy/url-classification-rule/toggle-all'
export const DOWNLOAD_PROTECTION_API = 'download-protection'
export const IS_ENABLED_DLP_API =
  'dlp-policy?join=readonlyRule&join=dlpWebRule&join=dlpWebRule.uploadPreventionRule||isEnabled&join=piiRule||isEnabled&fields=isRuleEnabled&join=watermarkRule&join=anonymizerRule&join=allowedEmailDomainsRule&join=sqlInjectionRule||isEnabled&join=dlpRecordings&join=pdfSecurityByPassRule&join=chatGPTCharacterLimitSetting'
export const DLP_RESTRICTION_MODE_API = 'dlp-policy?fields=isRestricted'
export const DLP_API = 'dlp-policy'
export const DLP_CONTROLLED_LINKS_API = 'dlp-controlled-links'
export const DLP_FORBIDDEN_LINKS_API = 'dlp-forbidden-links'
export const UPLOAD_RECORDING = 'upload-recording-rule'
export const DLP_RULES_API = 'dlp-policy?fields=rules'
export const PII_RULE_API = 'dlp-policy?join=piiRule&fields=id'
export const DATA_TYPES_PII_API = 'dlp-policy?join=piiRule||id,configurations&fields=id'
export const IS_ENABLED_PII_RULE_API =
  'dlp-policy?join=piiRule||id,isEnabled,requestAccess,allowCustom,restrictive,excelProtection&fields=id'
export const UPLOAD_PREVENTION_URLS_API = 'url-upload-rule'
export const FILES_GROUPS_API = 'download-policy/file-extension-group'
export const DLP_UPLOAD_SIZE_API = 'upload-size-restriction'
export const DLP_UPLOAD_PREVENTION_API =
  'dlp-policy?join=dlpWebRule||id&join=dlpWebRule.uploadPreventionRule&join=dlpWebRule.uploadPreventionRule.uploadPreventionUrlRule&join=dlpWebRule.uploadPreventionRule.blockedFileExtensionGroups&fields=id,uploadPreventionAllowedUrlRuleEnabled'
export const READ_ONLY_API = 'dlp-policy?fields=id&join=readonlyRule&fields=id'
export const READ_ONLY_URLS_API = 'readonly-urls'
export const UPLOAD_PREVENTION_RULE_API = 'upload-prevention-url-rule'
export const UPLOAD_RULE_API = 'upload-prevention-rule'
export const GROUP_NAME_API = 'groups?fields=name,alias'
export const GROUP_POLICY_TARGET =
  'groups?fields=name,policyTarget,alias,duplications,isDuplicated&join=ipLocation'
export const GROUP_IN_SCOPE =
  'groups?fields=name,policyTarget,alias,duplications,isDuplicated&join=ipLocation&filter=isDuplicated||$eq||false'
export const POLICY_CONFIG_API = 'policy-configs'
export const POLICY_CONFIG_API_BOOKMARK_PROXY_GATEWAYCONTROL =
  'policy-configs?filter=policy||in||Bookmark,Proxy,GatewayControl'
export const POLICY_DUPLICATE_API = 'groups/duplicate-policies'
export const GROUP_DUPLICATE_API = 'groups/duplicate-groups-from-target-policies'
export const IP_LOCATIONS_API = 'ip-locations'
export const POLICY_CONFIG_BULK_API = 'policy-configs/bulk'
export const UPLOAD_LIST_URLS_API = 'upload-prevention-allowed-url'
export const UPLOAD_REGEX_RULES_API = 'upload-regex-rules'
export const BOOKMARKS_MANAGEMENT_API =
  'dlp-policy?join=bookmarkRule&join=bookmarkRule.bookmarkFolders&join=bookmarkRule.bookmarkFolders.bookmarks&join=bookmarkRule.bookmarks&fields=id'
export const PROXY_API = 'proxy-policy'
export const BOOKMARK_API = 'bookmark'
export const EXCEPTION_DOMAIN_API = 'url-exceptions'
export const JIT_CONFIG_API = 'jit-configuration'
export const CS_EXCLUSION_CONFIG_API = 'content-script-exclusion-config'
export const JIT_DOMAIN_API = 'jit-domains'
export const CS_EXCLUSION_DOMAINS_API = 'content-script-exclusion-domains'
export const AGE_CHECK_DOMAINS_API = 'domain-age-urls'
export const NAV_CAPTURE_CONFIG_API = 'nav-capture-config'
export const NAV_CAPTURE_DOMAIN_API = 'nav-capture-domains'
export const NAV_ACTIVE_SITE_CAPTURE = 'nav-active-sites-time'
export const DEVICE_POSTURE_CHECK_API = 'device-posture-checks-policies'
export const DCP_REGISTRY_KEYS_API = 'dpc-registry-keys'
export const DCP_FILE_EXIST_API = 'dpc-file-exist'
export const DCP_UPLOAD_CERTIFICATE_API = 'dpc-upload-certificate'
export const DCP_PROCESSES_EXIST_API = 'dpc-ps-list'
export const DCP_PROCESSES_DOMAINS = 'dpc-ps-domains'
export const ACCESS_VIOLATION_API = 'access-violation?join=client'
export const PERSON_LOGIN_URL_API = 'authentication-policy/personal-login-url'
export const PERSONAL_LOGIN_RULE = 'authentication-policy/personal-login-rule'
export const LOGIN_ENFORCEMENT_DOMAINS_API = 'authentication-policy/login-enforcement-domains'
export const ACCESS_VIOLATION_EXPORT_CSV_API = 'access-violation/export-csv'
export const AUTH_PERSONAL_LOGIN_API =
  'authentication-policies?join=updatedBy||id&join=createdBy||id&fields=id&join=personalLoginRule'
export const SSO_INTEGRATION_API = 'sso-configuration'
export const USERS_CLIENT_API = 'clients'
export const ADMINS_USERS_API = 'users'
export const GROUPS_API = 'groups'
export const WATERMARK_RULE_API = 'dlp-policy?fields=isRuleEnabled&join=watermarkRule'
export const WATERMARK_LINK_API = 'watermark-link'
export const EXTENSION_URL_UPLOAD_RULE =
  'dlp-policy?join=dlpWebRule&join=dlpWebRule.uploadPreventionRule||blockAllMimeTypes,isEnabled&fields=id'
export const LOGIN_INTEGRATIONS_API = 'login-integration'
export const GUACAMOLE_INTEGRATION =
  'login-integration?filter=name||eq||Guacamole&filter=isEnabled||eq||true'
export const MPI_INTEGRATION = 'login-integration?filter=name||eq||MIP&filter=isEnabled||eq||true'
export const CIO_STATS_API = 'cio-stats'
export const COMPANY_AGGREGATE_API = 'cio-stats/company-aggregate'
export const REGEX_FILTERING_API = 'regex-filtering'
export const CLASSIFICATION_GROUPING = 'url-classification'
export const FEEDBACK_CLIENT_LIST = 'feedback/clients-list'
export const ADMIN_CSV_IMPORT = 'users/import-from-csv'
export const ADMIN_CSV_EXPORT = 'export-csv'
export const COMPANY_CONFIG = 'company-config'
export const CUSTOM_HEADER_DOMAIN = 'custom-headers-domains'
export const MEETING_DOMAIN = 'meeting-domains'
export const APPROVED_FILE_EXTENSION_MODE =
  'download-policies?join=approvedFileExtensionsRule||isRestricted,mode,enabledCategories,allowExceptionUrls&fields=id&join=createdBy||id&join=updatedBy||id&join=downloadPreventionAdvancedPolicies||id,isEnabled,blockAll,blockedMimeTypes'
export const ALERT_FILE_EXTENSION_MODE =
  'download-policies?join=alertFileExtensionsRule||isRestricted,mode,enabledCategories,allowExceptionUrls&fields=id&join=createdBy||id&join=updatedBy||id'
export const APPROVED_FILE_EXTENSION_URL = 'approved-file-extension-url'
export const ALERT_FILE_EXTENSION_URL = 'alert-file-extension-url'
export const COMPANY_API = 'company'
export const DOMAIN_AGE_API = 'domain-age-rule'
export const CLASSIFIED_DOMAINS_API = 'classified-domains'
export const REVISIT_DOMAIN_CLASSIFICATION = 'classified-domains/revisit-domain'
export const RECLASSIFY_DOMAIN_API = 'classified-domains/reclassify-domain'
export const INTERNAL_DOMAIN_API = 'internal-domains'
export const CLIENT_BASIC_INFO_API = 'clients?fields=login,firstName,lastName'
export const DOMAIN_LOOKUP_VENDOR_ORDER =
  'app-configuration?filter=name||eq||DomainLookupVendorOrder'
export const PII_RULE_ENABLED_API =
  'dlp-policy?join=piiRule||isEnabled&fields=id&join=sqlInjectionRule||isEnabled'
export const GET_NAVIGATION_THRESHOLD_API =
  'app-configuration?filter=name||eq||NavigationThresholdLimitConfig'
export const ADD_SSO_LINK = 'saas-links/manual'
export const HIBERNATE_POLICY_API = 'authentication-policies?fields=id&join=hibernateLogOffRule'
export const RPA_POLICY_API = 'authentication-policies?fields=id&join=rpaRule'
export const RPA_DOMAINS_API = 'rpa-domains'
export const ZOHO_WORKDRIVE_FOLDERS_API = 'zoho-workdrive-folders'
export const SYNC_ZOHO_WORKDRIVE = 'zoho-workdrive-folders/synchronize-clients'
export const TRUSTED_SITE_DIRECTIVE = 'trusted-site-directive'
export const TRANSLALATE_URL = 'translate-url'
export const LOGIN_INTEGRATION_CONFIG = 'login-integration-config'
export const SCHEDULE_EMAIL_REPORT = 'schedule-email-report'
export const INVITE_CLIENT_API =
  'lp-on-boarding/invite-users'
export const ANONYMIZER_API = 'anonymizer-link'
export const RECOGNIZER = 'recognizers'
export const COPY_EXCEPTION_API = 'copy-exceptions'
export const SQL_INJECTION_PATTERN_API = 'sql-injection-pattern'
export const SQL_INJECTION_RULE_API = 'sql-injection-rule?fields=isEnabled&join=sqlInjectPatterns'
export const CLIENT_TO_INVITE_API = 'clients?page=1&limit=500'
export const WEAK_PASSWORD_DETECTION = 'weak-password-check-rule'
export const PROCESSES_WHITELIST_RULE = 'whitelist-ps-rule'
export const PROCESSES_WHITELIST = 'whitelist-ps'
export const PHISHING_REGEX_API = 'phishing-regex-definition'
export const DOWNLOAD_EXCEPTION_MALWARESCAN_API = 'download-exception-url'
export const ADMX_RULES = 'admx-rules'
export const ADMX_CONFIG = 'admx-configuration'
export const SAFE_BROWSING_CONFIG = 'safe-browsing-config'
export const IFRAME_CONTENT_SCRIPT_CONFIG = 'iframe-contentscript-injection'
export const FORCE_LOGIN_UPDATE_API = 'authentication-policy/force-login-rule'
export const LOGIN_DOMAINS_ENFORCEMENT_UPDATE_API =
  'authentication-policy/login-domains-enforcement-rule'
export const ALLOWED_EMAIL_DOMAINS_API = 'allowed-email-domains'
export const PDF_SECURITY_BYPASS_DOMAINS_API = 'pdf-security-bypass-domains'
export const WEBFILTER_ACCESSIBLE_URL_IMPORT = 'accessible-urls/import-from-csv'
export const WEBFILTER_ACCESSIBLE_URL_EXPORT = 'accessible-urls/export-csv'
export const WEBFILTER_EXCEPTION_URL_IMPORT = 'url-exceptions/import-from-csv'
export const WEBFILTER_EXCEPTION_URL_EXPORT = 'url-exceptions/export-csv'
export const DLP_ALLOWED_EMAIL_DOMAINS_IMPORT = 'allowed-email-domains/import-from-csv'
export const DLP_ALLOWED_EMAIL_DOMAINS_EXPORT = 'allowed-email-domains/export-csv'
export const DLP_COPY_DISABLE_CONTEXT_MENU_API =
  'dlp-policy?join=disableContextMenuRule&fields=disableContextMenuRule'
export const DLP_COPY_DISABLE_CONTEXT_MENU_UPDATE_API = 'dlp-policy/disable-context-menu-rule'
export const DLP_RECORDING = 'dlp-recording'
export const DLP_SCREEN_CAPTURE_API = 'screen-capture-custom-rules'
export const CLIENTS_API = 'clients?fields=id,firstName,lastName,login'
export const MONITORED_APP_API =
  'nav-capture-config?fields=monitoredAppsEnabled,captureActivityEnabled,captureActivityIdleTime,activeSitesTimeEnabled,policyDisabled,monitoreAllApps,monitorAllAppsForceConsent,idleModeTime'
export const SELECT_CLIENT_API = 'clients?fields=login,firstName,lastName,fullIdentity'
export const DOMAIN_ANALYTICS_API = 'domain-analytics'
export const CLIENT_ACTIVITY_LOG_API = 'client-activity-logs'
export const CLIENT_RECORDINGS_API = 'client-recordings'
export const DLP_CONTROL_GROUPS = 'groups-of-controls'
export const DLP_CONTROL_GROUPS_ADVANCED_POLICY =
  'groups-of-controls?filter=groupName||eq||default&fields=groupId,groupName,protectedActions,piiProtectedActions,links'
export const NEW_ADVANCED_POLICIES = 'policies-rules'
export const SAAS_URL_API = 'saas-links?fields=url'
export const ADVANCED_POLICY_DLP =
  'dlp-policy?join=uploadPreventionAdvancedPolicies&join=readonlyRule&join=dlpWebRule&join=dlpWebRule.uploadPreventionRule&join=piiRule||isEnabled&fields=isRuleEnabled,rules,allowAccessRequest&join=watermarkRule||isAggressive,isEnabled,isExceptionAllowed&join=anonymizerRule||isEnabled&join=allowedEmailDomainsRule&join=sqlInjectionRule||isEnabled&join=dlpRecordings||isEnabled,pasteRecordingEnabled,uploadRecordingEnabled&join=pdfSecurityByPassRule&join=chatGPTCharacterLimitSetting&join=dlpWebRule.uploadPreventionRule.blockedFileExtensionGroups'
export const ADVANCED_POLICY_DOWNLOAD =
  'download-policies?join=approvedFileExtensionsRule&join=approvedFileExtensionsRule.fileExtensionGroupRules&join=approvedFileExtensionsRule.fileExtensionGroupRules.fileExtensionsGroup&join=approvedFileExtensionsRule.fileExtensionGroupRules.fileExtensionsGroup.fileExtensions&fields=id&join=createdBy||id&join=updatedBy||id&join=scrappingRule&join=secureStorageRule&join=fileEncryptionRule&join=downloadProtection||isEnabled,configurations&join=scrappingRule.scrappingConfigurations'
export const CUSTOM_FILE_EXTENSION_API = 'custom-file-extension'
export const WEB_ACCESS_RESTRICTION_API = 'web-access-restriction'
export const ADVANCED_POLICY_RULE_REODER = 'policies-rules/reorder'
export const AUTH_KEYLOG_POLICIES_RULE =
  'authentication-policies?join=updatedBy||id&join=createdBy||id&fields=id&join=forceLoginRule||isEnabled'
export const POLICIES_RULES_PHISHING =
  'phishing-policies?join=trustedDomainRule&join=exceptionDomainRule&join=commonSaasRule&join=phishingRegexRule&fields=id,policyDisabled'
//---------------- POLICIES RULES - CONTROLS APIS --------------------
export const POLICIES_RULES_UPLOAD_PREVENTION = 'upload-prevention-advanced-policies'
export const POLICIES_RULES_DLP =
  'dlp-policy?join=uploadPreventionAdvancedPolicies||isEnabled&join=readonlyRule||isEnabled&join=piiRule||isEnabled&fields=isRuleEnabled,rules,allowAccessRequest&join=watermarkRule||isEnabled&join=anonymizerRule||isEnabled&join=allowedEmailDomainsRule&join=sqlInjectionRule||isEnabled&join=pdfSecurityByPassRule&join=chatGPTCharacterLimitSetting||isEnabled'
export const POLICIES_RULES_WEB_ACCESS_RESTRICTION = 'web-access-restriction?fields=isEnabled'
