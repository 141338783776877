import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import SideNav from 'components/layout-components/SideNav'
import TopNav from 'components/layout-components/TopNav'
import Loading from 'components/shared-components/Loading'
import MobileNav from 'components/layout-components/MobileNav'
import HeaderNav from 'components/layout-components/HeaderNav'
import PageHeader from 'components/layout-components/PageHeader'
import Footer from 'components/layout-components/Footer'
import AppViews from 'views/app-views'
import { Layout, Grid } from 'antd'
import {
  getUserDetails,
  setNotificationsCount,
  refreshNotificationsFunc,
  setAlertsCount,
  refreshAlertsFunc,
} from 'redux/actions/Auth'
import navigationConfig from 'configs/NavigationConfig'
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
  DIR_RTL,
  DIR_LTR,
} from 'constants/ThemeConstant'
import utils from 'utils'
import { useThemeSwitcher } from 'react-css-theme-switcher'
import { useQuery } from 'react-query'
import { TrialUserView } from 'utils/roleRestrictions'
import { getNewAlertsCount } from 'services/alerts'
import { isLoggedIn } from 'services/admins'
import { env } from 'configs/EnvironmentConfig'
import { useIdleTimer } from 'react-idle-timer';
import { Modal, Button } from 'antd';
import { clearStorage } from 'utils/clearLocalStorage';
import { signOut } from 'redux/actions/Auth';
import store from 'redux/store'

const { Content } = Layout
const { useBreakpoint } = Grid
const timeout = 30 * 60 * 1000; // 1,800,000 ms (30 minutes)
const promptBeforeIdle = 2 * 60 * 1000; // 120,000 ms (2 minutes)

export const AppLayout = ({
  navCollapsed,
  navType,
  location,
  direction,
  getUserDetails,
  setAlertsCount,
  refreshAlertsFunc,
  companyDomain
}) => {
  // remaining time in milliseconds until the idle timeout is reached
  const [remaining, setRemaining] = useState(timeout);

  // controls the visibility of the modal
  const [modalVisible, setModalVisible] = useState(false);

  const [sessionExpired, setSessionExpired] = useState(false);

  const onIdle = () => {
    setSessionExpired(true); // Mark session as expired
    setModalVisible(true); // Show expiration warning
  };

  // Called when user becomes active
  const onActive = () => {
    setModalVisible(false);
  };

  // Called when the prompt period starts (2 minutes before idle)
  const onPrompt = () => {
    setModalVisible(true);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
  });
  const sessionExpiredRef = useRef(sessionExpired);
  useEffect(() => {
    sessionExpiredRef.current = sessionExpired;
  }, [sessionExpired]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (sessionExpiredRef.current) {
        clearStorage();
        store.dispatch(signOut());
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    // Update the remaining time every 500ms
    const interval = setInterval(() => {
      setRemaining(getRemainingTime());
    }, 500);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Called when the user chooses to continue the session
  const handleContinueSession = () => {
    activate(); // resets the idle timer
    setModalVisible(false);
  };

  // Called when the user chooses to log out
  const handleLogout = () => {
    setModalVisible(false);
    console.log('Logging out due to inactivity.');
    clearStorage()
    store.dispatch(signOut())
  };
  const { refetch: refetchAlerts } = useQuery(
    'new-access-violations-alerts',
    () => getNewAlertsCount(),
    {
      refetchOnWindowFocus: false,
      notifyOnStatusChange: true,
      enabled: !TrialUserView(),
      onSuccess: (count) => {
        setAlertsCount(count)
      },
    },
  )
  useEffect(() => {
    getUserDetails()
    refreshAlertsFunc(refetchAlerts)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    setTimeout(() => {
      window.postMessage({ type: 'ADMIN_MODE', state: true }, '*')
    }, 2000)
    window.addEventListener('beforeunload', () => {
      window.postMessage({ type: 'ADMIN_MODE', state: false }, '*')
    })
    return () => {
      window.postMessage({ type: 'ADMIN_MODE', state: false }, '*')
    }
  }, [])


  // Check if Office Next admin is logged in
  useEffect(() => {
    const checkLoggedInStatus = async () => {
      if (!env.IS_JIRAN && !companyDomain?.includes("dailytest")) return
      try {
        await isLoggedIn()
      } catch (error) {
        console.error('Error while checking logged in status:', error)
      }
    }
    const intervalId = setInterval(checkLoggedInStatus, 10e3)
    return () => clearInterval(intervalId)
  }, [companyDomain])

  const currentRouteInfo = utils.getRouteInfo(navigationConfig, location.pathname)
  const screens = utils.getBreakPoint(useBreakpoint())
  const isMobile = !screens.includes('lg')
  const isNavSide = navType === NAV_TYPE_SIDE
  const isNavTop = navType === NAV_TYPE_TOP
  const getLayoutGutter = () => {
    if (isNavTop || isMobile) {
      return 0
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH
  }

  const { status } = useThemeSwitcher()
  // When the prompt is shown, the remaining time will be within the prompt period (2 minutes)
  const countdownSeconds = Math.ceil(remaining / 1000);

  if (status === 'loading') {
    return <Loading cover="page" />
  }

  const getLayoutDirectionGutter = () => {
    if (direction === DIR_LTR) {
      return { paddingLeft: getLayoutGutter() }
    }
    if (direction === DIR_RTL) {
      return { paddingRight: getLayoutGutter() }
    }
    return { paddingLeft: getLayoutGutter() }
  }


  return (
    <Layout>
      <HeaderNav isMobile={isMobile} />
      {isNavTop && !isMobile ? <TopNav routeInfo={currentRouteInfo} /> : null}
      <Layout className="app-container">
        {isNavSide && !isMobile ? <SideNav routeInfo={currentRouteInfo} /> : null}
        <Layout className="app-layout" style={getLayoutDirectionGutter()}>
          <div className={`app-content ${isNavTop ? 'layout-top-nav' : ''}`}>
            <PageHeader display={currentRouteInfo?.breadcrumb} title={currentRouteInfo?.title} />
            <Content>
              <AppViews />
            </Content>
          </div>
          <Footer />
        </Layout>
      </Layout>
      {isMobile && <MobileNav />}
      {/* Modal for session prompt */}
      <Modal
        visible={modalVisible}
        title="Session Expiration Warning"
        closable={false}
        centered
        bodyStyle={{ padding: '20px', textAlign: 'center' }}
        footer={
          <div style={{ textAlign: 'center' }}>
            <Button key="logout" onClick={handleLogout} style={{ marginRight: 10 }}>
              Logout
            </Button>
            <Button key="continue" type="primary" onClick={handleContinueSession}>
              Continue Session
            </Button>
          </div>
        }
      >
        <p style={{ fontSize: '16px', marginBottom: '10px' }}>
          Your session is about to expire due to inactivity.
        </p>
        <p style={{ fontSize: '14px', color: '#ff4d4f' }}>
          You will be logged out in {countdownSeconds}{' '}
          {countdownSeconds === 1 ? 'second' : 'seconds'}.
        </p>
      </Modal>
      {/* Modal for session expiration */}
      <Modal
        visible={sessionExpired}
        title="Session Expired"
        closable={false}
        centered
        footer={
          <Button key="logout" onClick={handleLogout} type="primary">
            Login Again
          </Button>
        }
      >
        <p style={{ fontSize: '14px', color: '#ff4d4f' }}>Your session has expired due to inactivity.</p>
        <p>Please click "Login Again" to re-authenticate.</p>
      </Modal>
    </Layout>
  )
}

const mapStateToProps = ({ theme, auth }) => {
  const { navCollapsed, navType, locale } = theme;
  const { domain } = auth?.company ?? {}
  return { navCollapsed, navType, locale, companyDomain: domain }
}

const mapDispatchToProps = {
  getUserDetails,
  setNotificationsCount,
  setAlertsCount,
  refreshNotificationsFunc,
  refreshAlertsFunc,
}
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(AppLayout))
