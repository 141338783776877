import fetch from 'auth/FetchInterceptor'
import { computeHmac } from 'utils/hmac'

const JwtAuthService = {}

JwtAuthService.login = async (data) =>
  await fetch({
    url: `auth/sign-in`,
    method: 'post',
    headers: {
      'public-request': 'true',
      ...computeHmac(),
    },
    data,
  })
    .then((user) => user)
    .catch((err) => err)

JwtAuthService.getDetails = async (userId) =>
  await fetch({
    url: `users/${userId}?join=company`,
    method: 'get',
  })
    .then((user) => user)
    .catch((err) => err)

export default JwtAuthService
